<script>
export default {
  props: {
    select: {
      type: String,
      default: "projects",
      required: true,
    },
    selectOptions: {
      type: Array,
      default: () => [
        "Web Application",
        "Mobile Application",
        "UI/UX Design",
        "Branding & Anim",
      ],
    },
  },
};
</script>

<style lang="scss" scoped></style>
